export default class EscortEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#escort_uuid") ? (elem.querySelector("#escort_uuid") as HTMLInputElement).value : null,
            user_id: elem.querySelector("#new_escort_user_id") ? (elem.querySelector("#new_escort_user_id option:checked") as HTMLInputElement).value :  (elem.querySelector("#escort_user_id option:checked") as HTMLInputElement).value,
            ticket_number: elem.querySelector("#escort_ticket_number").value,
            visitor_name: elem.querySelector("#escort_visitor_name").value,
            visitor_company: elem.querySelector("#escort_visitor_company").value,

            from: elem.querySelector("#escort_from").value,
            to: elem.querySelector("#escort_to").value,
            sam_number: elem.querySelector("#escort_sam_number").value,
            serial: elem.querySelector("#escort_serial").value,
            grid: elem.querySelector("#escort_grid").value,
            wbs: elem.querySelector("#escort_wbs").value,
            cc: elem.querySelector("#escort_cc").value
        }
    }
}